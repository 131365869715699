import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IntroComponent } from './containers/intro/intro.component';
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {Ar2PageModule} from "./containers/ar2/ar2.module";
const routes: Routes = [
  {
    path: '',
    component: IntroComponent
  },
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'ar2',
    loadChildren: () => import('./containers/ar2/ar2.module').then( m => m.Ar2PageModule)
  },

];
@NgModule({
  imports: [
    BrowserModule, CommonModule, RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  declarations: [IntroComponent],
  exports: [IntroComponent, RouterModule]
})
export class AppRoutingModule {}
