// Configurator plugin implementation

import {Capacitor, registerPlugin} from '@capacitor/core';

import type { ConfiguratorPluginDefs } from './definitions';
import {ConfiguratorPluginMock} from './mock';

const ConfiguratorPlugin: ConfiguratorPluginDefs = Capacitor.getPlatform() === 'web' ?
    new ConfiguratorPluginMock() :
    registerPlugin<ConfiguratorPluginDefs>(
        'ConfiguratorPlugin',
    );


export * from './definitions';
export { ConfiguratorPlugin };
