// Configurator plugin definitions

export interface ConfiguratorPluginDefs {
    getProduct(opts: { url: string }): Promise<{uid: string}>;
    productFromData(opts: {data: JSON}): Promise<void>;
    setTracking(opts: { isEnabled: boolean }): void;
    uiFullScreen(opts: { isEnabled: boolean }): void;
    removeAll(): void;
    savePhoto(): Promise<void>;
}
