// Mock configurator class for web

import {ConfiguratorPluginDefs} from './definitions';
import {mockObjectId, wait} from '../../utils/utils';

export class ConfiguratorPluginMock implements ConfiguratorPluginDefs {
    public productions = [];

    async setTracking(opts: {isEnabled: boolean}): Promise<void> {
        await wait(500);
        return Promise.resolve();
    }

    async getProduct(opts: { url: string }): Promise<{uid: string}> {
        await wait(1000);
        const result = await fetch(opts.url);
        const productionJSON = await result.json();
        productionJSON.uid = mockObjectId();
        this.productions.push(productionJSON);

        return Promise.resolve({uid: productionJSON.uid});
    }

    async productFromData(opts: { data: JSON }): Promise<void> {
        return Promise.resolve();                
    }
    
    uiFullScreen(opts: { isEnabled: boolean }): void {
        console.info("UI fullscreen: " + opts.isEnabled);
    }
    
    removeAll(): void {
        console.info("Removed all products");
    }

    async savePhoto(): Promise<void> {
        console.info("Took screenshot");
    }
}
