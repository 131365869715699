/**
 * Downloads JSON file
 * @param {string} url
 * @returns {Promise<JSON>}
 */
export function loadJSON(url: string): Promise<JSON> {
    return fetch(url, {
        headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
        credentials: 'same-origin'
    })
        .then( response => response.json())
        .catch( () => { throw errMsg('loadJSON', `JSON problem - ${url}`); } );
}

/**
 * Throws an Error object with the given message.
 * @param {string} name
 * @param {string} massage
 * @returns {Error}
 */
export function errMsg(name: string, massage: string): Error {
    const error = new Error(massage);
    error.name = name;
    return error;
}

export function wait(ms: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    })
}

/**
 * Creates a random number ID
 * Do not use as real object ID - this is just for mocking
 */
export function mockObjectId(): string {
    return Math.round(Math.random() * 10000000000).toString();
}

export function triggerDocumentEvent(name, detail): void {
    const event = new CustomEvent(name, { detail });
    document.dispatchEvent(event);
}
